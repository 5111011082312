import Head from "next/head"
import { DrupalNode} from "next-drupal"
import { drupalInternal } from "lib/drupal"
import {DrupalJsonApiParams} from "drupal-jsonapi-params";
import { useTranslation } from "next-i18next";
import { getGlobalElements } from "lib/get-global-elements";
import * as process from "process";
import {NodeCampaignsList} from "../components/nodes/node--campaigns--list";
import {getParams} from "../lib/get-params";
import {
    LayoutOgilvyPerformance
} from "../components/ogilvy-performance/layout-ogilvy-performance";
import {LayoutProps} from "../components/layout/layout";
import Cookies from "js-cookie";
import {Meta} from "../components/meta";

export interface OgilvyPerformancePageProps extends LayoutProps {
    campaigns: DrupalNode[],
    expertize: DrupalNode[]
}

export default function OgilvyPerformancePage({ campaigns, expertize, menus }: OgilvyPerformancePageProps) {
    const { t, i18n } = useTranslation('ogilvy-performance');
    const currentLang = i18n.language;

    let expertize_performance = expertize[0];

    const domain = Cookies.get('xdomain') ? Cookies.get('xdomain') : "default";

    let tags = [];
    tags.push({
        tag: 'title',
        attributes: {
            name: 'title',
            content: t("metatitle")
        }
    });
    tags.push({
        tag: "meta",
        attributes: {
            property: "og:image",
            content: `${process.env.NEXT_PUBLIC_URL}/images/layout/logo.svg`
        }
    });
    tags.push({
        tag: "meta",
        attributes: {
            property: "og:image:width",
            content: 800
        }
    });
    tags.push({
        tag: "meta",
        attributes: {
            property: "og:image:height",
            content: 600
        }
    });

    return (
        <LayoutOgilvyPerformance menus={menus}>
            <Meta tags={tags} gtag_code={domain}/>

            <div className="container">
                <div id="campaigns">
                    <div id="campaigns_list">
                        <NodeCampaignsList campaigns={campaigns} />
                    </div>
                </div>
            </div>
        </LayoutOgilvyPerformance>
    )
}

export async function getServerSideProps(
    context
) {

    //const campaigns = [];

    let params_expertize = new DrupalJsonApiParams();
    params_expertize.addFilter('status',"1");
    params_expertize.addFilter('title','Performance');
    params_expertize.addFields("node--expertize",["id","title"]);
    const expertize_performance = await drupalInternal.getResourceCollectionFromContext(
        "node--expertize",
        context,
        {
            params: params_expertize.getQueryObject(),
        }
    );



        let expertize = expertize_performance[0];

        let params_campaign = getParams("node--campaign", "teaser", false)
        params_campaign.addFilter('status', "1");
        params_campaign.addFilter("field_expertize.id", 'c45a1d9f-bc5f-4837-969b-3b0011c5ac13'); //expertize.id );
        params_campaign.addSort("field_position", "ASC")
        params_campaign.addSort("created", "DESC")
        const campaigns = await drupalInternal.getResourceCollectionFromContext(
            "node--campaign",
            context,
            {
                params: params_campaign.getQueryObject(),
            }
        );


    return {
        props: {
            ...(await getGlobalElements(context,'ogilvy-performance')),
            campaigns: campaigns,
            expertize: expertize_performance
        },
    }
}
